$kairos-nav-bar-height: 60px;
$fulfillment-toolbar-height: 50px;
$task-detail-header-height: 60px;
$task-detail-overview-height: 60px;
$task-detail-resolution-height: 50px;
$navigation-content-min-width: 270px;
$common-navigation-width: 270px;
$view-by-dropdown-height: 22px;
$table-header-height: 36px;

$tasks-performance-summary-title-height: 20px;

$container-margin-small: 6px;
$container-margin-large: 12px;
$container-padding-large: 100px;

// font sizes
$font-xxsmall: 0.5rem; /* 8px */
$font-xsmall-minus: 0.5625rem; /* 9px */
$font-xsmall: 0.625rem; /* 10px */
$font-xsmall-plus: 0.6875rem; /* 11px */
$font-small: 0.75rem; /* 12px */
$font-small-plus: 0.8125rem; /* 13px */
$font-base: 0.875rem; /* 14px */ /* middle */
$font-middle-plus: 0.9375rem; /* 15px */
$font-medium: 1rem; /* 16px */ /* base */
$font-base-plus: 1.125rem; /* 18px */
$font-large-minus: 1.1875rem; /* 19px */
$font-large: 1.25rem; /* 20px */
$font-large-plus: 1.375rem; /* 22 */
$font-xlarge: 1.5rem; /* 24px */
$font-xxlarge: 2rem; /* 32px */
$font-xxlarge-plus: 2.5rem; /* 40px */
$font-xxxlarge: 3rem; /* 48px */
$font-xxxxlarge: 4rem; /* 48px */
$font-dot-size: 100px;
$font-header-size: 2.25rem;

// font weights scale
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

$spacing-small: 0.5rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

//crm form field
$form-width: 250px;
$form-padding: 0.429em;

// crm_panel-left
$input-width: 200px;
$input-width-small: 300px;
$input-width-max: 750px;

// element size
$elem-size-0: 0px;
$elem-size-1: 1px;
$elem-size-2: 2px;
$elem-size-4: 4px;
$elem-size-5: 5px;
$elem-size-8: 8px;
$elem-size-10: 10px;
$elem-size-11: 11px;
$elem-size-12: 12px;
$elem-size-14: 14px;
$elem-size-16: 16px;
$elem-size-20: 20px;
$elem-size-24: 24px;
$elem-size-25: 25px;
$elem-size-30: 30px;
$elem-size-33: 33px;
$elem-size-35: 35px;
$elem-size-36: 36px;
$elem-size-40: 40px;
$elem-size-50: 50px;
$elem-size-60: 60px;
$elem-size-70: 70px;
$elem-size-100: 100px;
$elem-size-130: 130px;
$elem-size-150: 150px;
$elem-size-190: 190px;
$elem-size-200: 200px;
$elem-size-230: 230px;
$elem-size-250: 250px;
$elem-size-300: 300px;
$elem-size-400: 400px;
$elem-size-450: 450px;
$elem-size-500: 500px;
$elem-size-600: 600px;
$elem-size-570: 570px;
$elem-size-750: 750px;
$elem-size-950: 950px;
$elem-size-1200: 1200px;
// icon size
$icon-width: 25px;
$icon-height: 25px;
$icon-margin: 12px 0px;

//thumbnail size
$thumbnail-width: 50px;
$thumbnail-height: 50px;

//button-bar padding
$padding-zero: 0px;
$padding-small: 5px;
$padding: 10px;
$padding-min-top: 10px;
$padding-min-bottom: 10px;
$padding-max-top: 20px;
$padding-max-bottom: 20px;
$margin-right: 20px;
$border-radius: 5px;
$padding-min-max: 10px 20px;
$padding-right: 35px;
$margin: 10px 5px;
$margin-zero: 0px;
$margin-small: 5px;

//form content
$content-width: 570px;
$content-full-width: 100%;
$form-width: 50%;
//crm form field
$form-width: 250px;
$form-padding: 0.429em;
$large-form-width: 1290px;

// percent
$percent-twelve: 12%;
$percent-fifteen: 15%;
$percent-fifty: 50%;
$percent-seventy-five: 75%;
$percent-ninety: 90%;
$percent-ninety-five: 95%;
$percent-ninety-seven: 97%;
$percent-hundred: 100%;

//modal and content width and height
$modal-height: 250px;
$modal-height-large: 500px;
$modal-width: 500px;
$modal-width-large: 1000px;
$modal-content-height: 150px;
$p-height: 50px;

// Grid template
$grid-template-1: 1fr 1fr;

//add-refresh button
$add-refresh-padding: 20px 0px 20px 5px;
$width-ninety-eight: 98%;
