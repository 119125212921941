@import './colors';
@import './sizes';

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin get-scrollbar-styles($background) {
  @if $background == 'white' {
    scrollbar-base-color: white;
    scrollbar-face-color: white;
    scrollbar-3dlight-color: white;
    scrollbar-highlight-color: white;
    scrollbar-track-color: white;
    scrollbar-arrow-color: white;
    scrollbar-shadow-color: white;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
        background-color: $color-lightbg-scrollbar;
      }

      scrollbar-face-color: $color-lightbg-scrollbar;
      scrollbar-arrow-color: $color-lightbg-scrollbar;
    }
  } @else if $background == 'light' {
    scrollbar-base-color: $color-lightbg-body;
    scrollbar-face-color: $color-lightbg-body;
    scrollbar-3dlight-color: $color-lightbg-body;
    scrollbar-highlight-color: $color-lightbg-body;
    scrollbar-track-color: $color-lightbg-body;
    scrollbar-arrow-color: $color-lightbg-body;
    scrollbar-shadow-color: $color-lightbg-body;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $color-lightbg-scrollbar-track;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
        background-color: $color-lightbg-scrollbar;
      }

      scrollbar-face-color: $color-lightbg-scrollbar;
      scrollbar-arrow-color: $color-lightbg-scrollbar;
    }
  } @else if $background == 'dark' {
    scrollbar-base-color: $s-02-00;
    scrollbar-face-color: $s-02-00;
    scrollbar-3dlight-color: $s-02-00;
    scrollbar-highlight-color: $s-02-00;
    scrollbar-track-color: $s-02-00;
    scrollbar-arrow-color: $s-02-00;
    scrollbar-shadow-color: $s-02-00;

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $s-02-00;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        display: block;
        background-color: $color-darkbg-hover;
      }

      scrollbar-face-color: $color-nav-dark;
      scrollbar-arrow-color: $color-nav-dark;
    }
  }
}

@mixin hide-scrollbar() {
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }
}

@mixin create-triangle($direction, $size, $color) {
  width: 0;
  height: 0;

  @if $direction == 'left' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == 'up' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == 'down' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
}

@mixin create-box-shadow($offsetX, $offsetY, $blurRadius, $spreadRadius, $color, $inset: '') {
  box-shadow: $offsetX $offsetY $blurRadius $spreadRadius $color unquote($inset);
}

@mixin placeholder-text-color($color) {
  ::-webkit-input-placeholder {
    color: $color;
  }
  :-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $color;
  }
  ::-ms-input-placeholder {
    color: $color;
  }
}

@mixin placeholder-font-size($size) {
  ::-webkit-input-placeholder {
    font-size: $size;
  }
  :-moz-placeholder {
    font-size: $size;
  }
  ::-moz-placeholder {
    font-size: $size;
  }
  :-ms-input-placeholder {
    font-size: $size;
  }
  ::-ms-input-placeholder {
    font-size: $size;
  }
}

@mixin resource-mgmt-hashed-background() {
  background-image: linear-gradient(
    -45deg,
    rgba(200, 200, 200, 0.25) 5%,
    rgba(188, 188, 188, 0.1) 25%,
    rgba(188, 188, 188, 0.1) 50%,
    rgba(200, 200, 200, 0.25) 30%,
    rgba(200, 200, 200, 0.25) 55%,
    rgba(188, 188, 188, 0.1) 75%,
    transparent
  );
  background-size: 6px 6px;
}

@mixin fulfillment-past-shadow-background() {
  mix-blend-mode: multiply;
  background-color: $--n-22;
}

// multi-browser line clamp implementation
@mixin line-clamp($font-size, $line-height, $lines-to-show: 3, $background: $--n-00) {
  display: block;
  position: relative;
  max-height: $font-size * $line-height * $lines-to-show;
  font-size: $font-size;
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  &:before {
    bottom: 0;
    position: absolute;
    right: 0;
    float: right;
    content: '...';
    font-weight: bold;
    background-color: $background;
  }
  &:after {
    content: '';
    background: $background;
    position: absolute;
    height: $font-size * $line-height;
    width: 100%;
    z-index: 1;
  }
}

@mixin footer-button-layout() {
  footer {
    display: block;
    width: 100%;
    z-index: 1;
    padding: 0px;
    .capacity-model {
      display: block;
      label {
        color: $--n-66;
      }
    }

    .footer-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 6px;

      wfx-button {
        min-width: 67.5px;
        height: 20px;
        &.cancel {
          margin-right: 5px;
        }
      }

      :first-child {
        margin-right: 24px;
      }
    }
  }
}

@mixin dialog-form-layout() {
  @include footer-button-layout();

  header {
    display: none;
  }

  :host.dialog {
    height: 100%;
    width: 280px;

    display: flex;
    flex-direction: column;
    header {
      display: block;
      height: 30px;
      font-size: $font-medium;
      font-weight: bold;
      color: $--n-65;
    }
    section {
      height: calc(100% - 30px);
    }
    footer {
      height: 30px;
      margin: 0 0 6px 0;

      .footer-actions {
        padding: 0px;
      }
    }
  }
}

@mixin no-shifts() {
  .noShifts {
    position: absolute;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    line-height: 83px;
    color: $--n-65;
    font-size: $font-small;
    z-index: 1;
  }
}
