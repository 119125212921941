@import './colors';
@import './mixins';

%center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

%center-content-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%flex-row {
  display: flex;
  flex-direction: row;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%task-detail-tab-content-header {
  color: $color-kairos-blue;
  font-size: 14pt;
  font-weight: 300;
}

%transition-quick-ease-in-out {
  transition: 0.3s ease-in-out;
}

%transition-super-quick-ease-in-out {
  transition: 0.1s ease-in-out;
}

%transition-background-color {
  transition: background-color 0.3s ease-in-out;
}

%transition-collapse-width {
  transition: width 0.3s ease-in-out;
}

%transition-collapse-max-width {
  transition: max-width 0.3s ease-in-out;
}

%box-shadow-left-right {
  box-shadow: -10px 0px 20px -2px rgba(0, 0, 0, 0.6), 10px 0px 20px -2px rgba(0, 0, 0, 0.6);
}

%box-shadow-left-right-light {
  box-shadow: -2px 0px 2px -2px rgba(0, 0, 0, 0.6), 2px 0px 2px -2px rgba(0, 0, 0, 0.6);
}

%box-shadow-left-right-light-lightbg {
  box-shadow: 8px 0px 8px -6px rgba(0, 0, 0, 0.4), -8px 0px 8px -6px rgba(0, 0, 0, 0.4);
}

%box-shadow-left-right-bottom-light {
  box-shadow: -2px 3px 15px -2px rgba(0, 0, 0, 0.4),
    2px 3px 15px -2px rgba(0, 0, 0, 0.4),
    2px 3px 15px -2px rgba(0, 0, 0, 0.4);
}

%box-shadow-left-right-top-light {
  box-shadow: -2px -3px 15px -2px rgba(0, 0, 0, 0.4),
    2px -3px 15px -2px rgba(0, 0, 0, 0.4),
    2px -3px 15px -2px rgba(0, 0, 0, 0.4);
}

%inset-box-shadow-right-bottom-light {
  box-shadow: -10px 0px 20px -5px rgba(0, 0, 0, 0.4) inset, 0px -10px 20px -5px rgba(0, 0, 0, 0.4) inset;
}

%header {
  margin-bottom: 5px;

  color: $color-lightbg-light-font;
  font-size: 8pt;
}
