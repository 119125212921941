@import '../../common/colors';
@import '../../common/sizes';
@import '../../common/mixins';

/* p-table inside of shift-plan-details */
shift-plan-details,
shift-modifications {
  p-table .ui-table {
    width: 100%;
    * {
      text-align: left;
    }

    tr.shift-stripe {
      background: $--n-00;
      & > td {
        background: $--n-00;
      }
    }

    * th {
      font-size: $font-xsmall;
      font-weight: bold;
      color: $--n-30;
      text-overflow: ellipsis;

      &.name {
        width: 24%;
      }
    }

    * td {
      font-size: $font-xsmall !important;
      font-weight: normal;
      color: $--n-65;
      white-space: unset !important;
      &.days {
        display: flex;
        flex-direction: row;

        * {
          margin-right: 2px;
        }
      }
      &.name {
        font-weight: bold;
        color: $--n-65;

        .code {
          font-weight: bold;
          margin-right: 2px;
        }
      }
    }

    * .row-one,
    * .row-one-sp {
      width: 100%;
      height: 25px;
      border-top: 2px solid $--n-05;

      td {
        font-size: $font-xxsmall;
        color: $color-kairos-green-shifts;
        font-weight: 500;
        padding: 7px 0px 0px 7px !important;
        height: 25px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    * .row-two td {
      height: 25px;

      padding-bottom: 8px;
      padding-top: 0;
    }
  }
}

.stripe {
  background: $color-white-two !important;
  &:first-child {
    border-top: 1px solid $color-lightbg-body-accent-border;
  }
  &:last-child {
    border-bottom: 1px solid $color-lightbg-body-accent-border;
  }
  td {
    background: $color-white-two !important;
    * {
      background: $color-white-two !important;
    }
  }
}

.no-data {
  text-align: center !important;
  font-size: $font-xxsmall;
}

$tech-list-header-height: 45px;
$tech-list-tabs-height: 36px;
$tech-list-tools-height: 30px;
$tech-selection-min-width: 85px;
$dropdown-option-height: 24px;

.ui-table {
  width: 100%;
  height: 100%;

  .ui-table-scrollable-wrapper {
    height: 100%;
    .ui-table-scrollable-view {
      height: 100%;
      .ui-table-scrollable-body {
        overflow-y: scroll;

        @include get-scrollbar-styles('light');

        height: calc(
          100vh - #{$tech-list-header-height} - #{$kairos-nav-bar-height} - #{$fulfillment-toolbar-height} -
            #{$tech-list-tools-height}
        );

        .ui-table-scrollable-body-table {
          width: 100%;
          margin: 0px;
          .ui-table-tbody {
            tr {
              margin: 0px;
              padding: 0px;
              td {
                margin: 0px;
                padding: 0px;
              }
            }
          }
        }
      }
    }
  }
}
shift-modifications {
  p-table {
    .ui-table {
      tr.shift-stripe {
        background: $--n-05;
        & > td {
          background: $--n-05;
          & > * {
            background: $--n-05;
          }
        }
      }
      * tr.row-one {
        border-color: white;
        td {
          color: $color-unselected-tab-font;
        }
      }
    }
  }
}

.p-datatable-resizable-table>.p-datatable-thead>tr>th, .p-datatable-resizable-table>.p-datatable-tfoot>tr>td, .p-datatable-resizable-table>.p-datatable-tbody>tr>td
{
  white-space: unset !important;
}