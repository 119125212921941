@import '../../common/colors';
@import '../../common/sizes';
@import '../../common/mixins';

.ui-accordion {
  .ui-accordion-header {
    a {
      padding: 0;

      text-decoration: none;

      cursor: default;
    }

    .ui-accordion-toggle-icon {
      display: none;
    }
  }

  .ui-accordion-content {
    @include get-scrollbar-styles('light');
    padding: 0;
    max-height: 100%;
    overflow-y: auto;
    overflow: initial;

    .bottom-shadow {
      content: '';
      height: 8px;
      width: 100%;
      left: 0px;
      bottom: 1px;
      z-index: -1;
      box-shadow: inset 0 -6px 6px -2px rgba(0, 0, 0, 0.1);
    }
  }
}

#shift-plan-details-accordion {
  .ui-accordion {
    .ui-accordion-header {
      a {
        height: 60px;
      }
    }
  }
}

.ui-accordion-content * p-tabView div.pill-tabs > ul {
  height: 24px;

  box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0) !important;

  li {
    height: 24px;
    background: transparent !important;
    box-shadow: none !important;
    border: 0px none !important;
    &:hover {
      background: transparent !important;
      box-shadow: none !important;
      border: 0px none !important;
    }

    padding: 0px;
  }

  li a {
    background: transparent;
    color: $color-unselected-tab-font;
    font-size: $font-small !important;
    font-weight: 300;
    padding: 6px 12px !important;
  }

  .ui-tabview-selected a {
    line-height: 16px;
    background: $color-kairos-blue;
    color: $color-white !important;
    border-radius: 14px;
    box-shadow: none !important;
    font-weight: 600;
  }
}
