/* Purchase the Designer API to get full access to the theme engine. */
/* Purchase the Designer API to get full access to the theme engine. */
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body .p-component,
body .p-component .p-component {
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  font-size: 0.75rem;
}
body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body a {
  color: #007ad9;
  text-decoration: none;
}
body a:hover {
  color: #116fbf;
}
body a:active {
  color: #005b9f;
}
body .p-reset {
  line-height: normal;
}
body .p-disabled, body .p-component:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

/* Purchase the Designer API to get full access to the theme engine. */
body .ui-inputtext {
  font-size: 14px;
  color: #333333;
  background: #ffffff;
  padding: 0.429em;
  border: 1px solid #a6a6a6;
  -moz-transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #212121;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-color: #007ad9;
  outline: 0 none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
body .ui-inputtext.ng-dirty {
  border-color: #a80000;
}
body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}

body .ui-inputswitch {
  width: 3em;
  height: 1.75em; 
}

body .ui-inputswitch .ui-inputswitch-slider {
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
    border-radius: 30px;
    background: #cccccc;
    }
    body .ui-inputswitch .ui-inputswitch-slider:before {
      background-color: #ffffff;
      height: 1.250em;
      width: 1.250em;
      left: .25em;
      bottom: .25em;
      border-radius: 50%;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      }
  body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
    -webkit-transform: translateX(1.25em);
    -ms-transform: translateX(1.25em);
    transform: translateX(1.25em); }
  body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
    background: #b7b7b7; }
  body .ui-inputswitch:not(.p-disabled):hover .ui-inputswitch-slider {
    background-color: #b7b7b7;
    }
  body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
    background-color: #7baf5e;
    }
  body .ui-inputswitch.ui-inputswitch-checked:not(.p-disabled):hover .ui-inputswitch-slider {
    background-color: #5fb82f;
    }
  body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
    background: #235f03; }

body .ui-chkbox .ui-chkbox-box {
  border: 1px solid #a6a6a6;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  -o-transition: background-color 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
body .ui-chkbox .ui-chkbox-box:not(.p-disabled):hover {
  border-color: #212121;
}
body .ui-chkbox .ui-chkbox-box.p-focus {
  border-color: #007ad9;
  background-color: #ffffff;
  color: #007ad9;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #007ad9;
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.p-disabled):hover {
  border-color: #005b9f;
  background-color: #005b9f;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active.p-focus {
  border-color: #005b9f;
  background-color: #005b9f;
  color: #ffffff;
}
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 18px;
}
body .ui-chkbox-label {
  margin: 0 0 0 0.5em;
}
body .ui-listbox {
  padding: 0;
  min-width: 12em;
  background: #ffffff;
  border: 1px solid #a6a6a6;
}
body .ui-listbox .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: #007ad9;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item.p-highlight {
  color: #ffffff;
  background-color: #8bc63f;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox:not(.p-disabled) .ui-listbox-item:not(.p-highlight):hover {
  color: #333333;
  background-color: #eaeaea;
}
body .ui-listbox.p-disabled .ui-chkbox-box:not(.p-disabled):not(.ui-state-active):hover {
  border: 1px solid #a6a6a6;
}
body ::-webkit-input-placeholder {
  color: #666666;
}
body :-moz-placeholder {
  color: #666666;
}
body ::-moz-placeholder {
  color: #666666;
}
body :-ms-input-placeholder {
  color: #666666;
}

/* Purchase the Designer API to get full access to the theme engine. */
body .p-button {
  margin: 0;
  color: #ffffff;
  background-color: #8bc63f;
  border: 1px solid #8bc63f;
  font-size: 14px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
body .p-button:enabled:hover {
  background-color: #8bc63f;
  color: #ffffff;
  border-color: #8bc63f;
}
body .p-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  background-color: #8bc63f;
}
body .p-button:enabled:active {
  background-color: #8bc63f;
  color: #ffffff;
  border-color: #8bc63f;
}
.p-fileupload-choose:not(.p-disabled):hover {
  background: #8bc63f;
  color: #ffffff;
  border-color: #8bc63f;
}
body .p-button.p-button-text-only .p-button-text {
  padding: 0.429em 1em;
}
body .p-button.p-button-text-icon-left .p-button-text {
  padding: 0.429em 1em 0.429em 2em;
}
body .p-button.p-button-text-icon-right .p-button-text {
  padding: 0.429em 2em 0.429em 1em;
}
body .p-button.p-button-icon-only {
  width: 2.143em;
}
body .p-button.p-button-icon-only .p-button-text {
  padding: 0.429em;
}
body .p-button.p-button-raised {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
body .p-button.p-button-rounded {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
body .ui-fluid .p-button-icon-only {
  width: 2.143em;
}

/* Purchase the Designer API to get full access to the theme engine. */
body .p-component-content p {
  line-height: 1.5;
  margin: 0;
}
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
  height: 100%;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  background: transparent;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 0px solid #c8c8c8;
  background-color: #f4f4f4;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
  color: #848484;
  margin-right: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
  color: #848484;
  margin-left: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 -0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover {
  background-color: #dbdbdb;
  border: 1px solid #dbdbdb;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover a .ui-tabview-right-icon {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.p-disabled):hover .ui-tabview-close {
  color: #333333;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: white;
  border: 0px solid white;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #4b8cb6;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a .ui-tabview-right-icon {
  color: #4b8cb6;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
  color: #4b8cb6;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border: 0px solid #005b9f;
  background-color: #dbdbdb;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
  color: #ffffff;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 0px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  margin-right: 0px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  margin-bottom: 0px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  margin-bottom: 0px;
}
body .ui-tabview .ui-tabview-panels {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 0px solid #c8c8c8;
  color: #333333;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  height: 100%;
  padding: 0;
}

/* Purchase the Designer API to get full access to the theme engine. */
body .ui-picklist .ui-picklist-buttons button {
  font-size: 16px;
}
body .ui-picklist .ui-picklist-caption {
  background-color: #f4f4f4;
  color: #333333;
  border: 0px solid #c8c8c8;
  padding: 0.571em 1em;
  font-weight: 700;
}
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 0px solid #c8c8c8;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none;
  border-top: 0 none;
}
body .ui-picklist .ui-picklist-filter-container input {
  width: 100%;
  padding-right: 2em;
}
body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #007ad9;
}
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em;
}
body .ui-picklist .ui-picklist-list {
  border: 0px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.p-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
@media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em;
  }
}

/* Purchase the Designer API to get full access to the theme engine. */
/* Purchase the Designer API to get full access to the theme engine. */
/* Purchase the Designer API to get full access to the theme engine. */
body .ui-breadcrumb {
  background-color: #ffffff;
  border: 0px solid #c8c8c8;
  padding: 0.571em 1em;
}
body .ui-breadcrumb ul li {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #333333;
  margin: 0;
  float: none;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #737373;
}
body .ui-breadcrumb ul li:first-child a {
  color: #737373;
  margin: 0;
  font-size: 18px;
}

/* Purchase the Designer API to get full access to the theme engine. */
/* Add your customizations of theme here */
/* Purchase the Designer API to get full access to the theme engine. */
body .p-component-content p {
  line-height: 1.5;
  margin: 0;
}

body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  min-height: 55px;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  padding: 1.3em 1em;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

body .ui-tabview.ui-tabview-right:nth-child(1) .ui-tabview-panels {
  width: calc(100% - 50px);
}

body .ui-tabview.ui-tabview-right:nth-child(1) > .ui-tabview-nav {
  width: 50px !important;
}

body .ui-tabview .ui-tabview-panels {
  height: 100%;
  padding: 0px;
  z-index: auto;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  padding: 0;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 0.5rem 0rem;
}

.p-tabview .p-tabview-nav {
  background: #34373a;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #8bc63f;
  border-color: #ffffff;
  color: #ffffff;
  padding: 10px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #ffffff;
  border-width: 0 0 1px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #080808;
  padding: 10px;
  font-weight: 700;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: box-shadow 0.2s;
  margin: 0 0 -2px 0;
  margin-right: 1px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: none;
  position: absolute;
  bottom: 0;
  height: 2px;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav li {
  margin-right: 2px;
  min-height: 34px;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #ffffff;
  color: #080808;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
  z-index: inherit;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 6px;
  font-size: .75rem;
}

.p-multiselect .p-multiselect-label {
  padding: 6px;
}

.p-dropdown {
  min-width: 250px;
}
.p-dialog {
  background-color: #ffffff;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #8bc63f;
  color: #ffffff;
  padding: 0.2rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  margin: 6px 6px 0px 6px !important;
}

.p-dialog .p-dialog-content {
  overflow: auto;
  margin: 0px 6px 0px 6px !important;
  border-right: none; 
  border-left: none;
  z-index: 1;
  padding: 10px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border: 1px solid #c8c8c8;
}

.p-dialog {
  height: max-content;
  background-color: #ffffff;
  min-width: 600px;
  min-height: 450px;
}

.p-tabview-nav {
  display: flex;
  flex-flow: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1 1 auto;
}

.p-listbox .p-listbox-list {
   padding: 0px 0px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.571em 1em;
  font-weight: 700;
  transition: background-color 0.2s;
  border: 1px solid #c8c8c8;
  color: #333333;
  background-color: #f4f4f4;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: #8bc63f !important;
  border: 1px solid #8bc63f !important;
  color: #ffffff;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: white;
  background-color: #007ad9 !important;
}
/*# sourceMappingURL=theme.css.map */
