@import '../common/buttons';
@import '../common/sizes';

@mixin calendar_style {
  .date-picker {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $--n-05;
    border-radius: 4px;
    padding-left: 10px;
    color: $--n-52;
    font-weight: 600;
    &.active {
      background-color: $p-01-00c;
      color: $--n-00;
    }
    .fa {
      font-weight: 600;
      margin-right: 5px;
      font-size: $font-base;
    }
    span {
      font-weight: 600;
      line-height: 1;
      font-size: $font-xsmall;
    }
  }

  .ui-datepicker {
    .ui-datepicker-prev span,
    .ui-datepicker-next span {
      border-radius: 50% !important;
      padding: 2px !important;
      background: $--n-12 !important;
    }
    .ui-datepicker-group {
      .ui-datepicker-calendar-container {
        padding: 0 20px;
      }
      .ui-datepicker-header {
        .ui-datepicker-title {
          font-size: $font-small;
          font-weight: bold;
          color: $--n-65;
        }
      }
      .ui-datepicker-buttonbar {
        .ui-g {
          display: flex;
          justify-content: center;
          & > div {
            height: auto;
            width: auto;
            button {
              background-color: $p-01-00c;
              border: 0;
              span {
                font-size: 8px;
                color: $--n-00;
                padding: 2px 15px;
                font-weight: bold;
              }
            }
            &:last-child {
              display: none;
            }
          }
        }
      }
      .ui-datepicker-prev,
      .ui-datepicker-next {
        display: flex;
        top: 0.6em;
        align-items: center;
        justify-content: center;
        background-color: $--n-12;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        & > span {
          display: flex;
          position: static;
          margin-top: 0;
          margin-left: 0;
          &:before {
            font-size: 10px;
          }
        }
      }
      .ui-datepicker {
        th {
          font-size: $font-xxsmall;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          color: $--n-65;
        }
        td {
          & > span,
          & > a {
            font-size: $font-xsmall-minus;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
          }
          & > span {
            color: $--n-15;
          }
          & > a {
            color: $--n-46;
            &.ui-state-active {
              color: $--n-00;
            }
          }
        }
      }
    }
    .ui-datepicker-buttonbar .ui-g {
      .ui-g-6:first-of-type {
        text-align: right;
        padding-right: 6px;
        .p-button {
          float: right;
        }
      }

      .ui-g-6:last-of-type {
        text-align: left;
        padding-left: 6px;
        .p-button {
          float: left;
        }
      }
      .p-button {
        @include button_style;
        height: 18px;
        .p-button-text {
          font-size: $font-xsmall;
          padding: 2px 12px;
        }
      }
    }
    &.show-clear {
      .ui-datepicker-buttonbar {
        .ui-g {
          & > div {
            &:last-child {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
