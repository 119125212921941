// Color palette
// Neutral Grays
$--n-00: #ffffff;
$--n-03: #f4f4f4;
$--n-05: #f0f0f0;
$--n-09: #ececec;
$--n-12: #e1e1e1;
$--n-15: #d9d9d9;
$--n-16: #cacaca;
$--n-22: #bcbcbc;
$--n-24: #b6b6b6;
$--n-30: #a9a9a9;
$--n-38: #8e8e8e;
$--n-46: #85898b;
$--n-52: #777777;
$--n-60: #636363;
$--n-65: #5f5f5f;
$--n-66: #585858;
$--n-68: #525252;
$--n-70: #474a4b;
$--n-77: #3a3e40;
$--n-80: #33373b;
$--n-82: #2f3133;
$--n-85: #2a2d2f;
$--n-87: #222527;
$--n-89: #1e2022;
$--n-94: #111213;

// Primary
$p-01-01: #c3dce0;
$p-01-02: #7abce6;
$p-01-00a: #569dca;
$p-01-03a: #4a8cb6;
$p-01-00: #47a1c3;
$p-01-00c: #47a1c3;
$p-01-03: #3f80ab;
$p-01-00b: #497c9c;
$p-01-04: #184c6d;
$p-01-05: #325967;
$p-01-06: #294954;

// Secondary
$s-02-04: #e8e9e9;
$s-02-05: #e2e4e5;
$s-01-05: #d5d8d9;
$s-01-04: #c9ced1;
$s-01-01: #c1c5c8;
$s-01-03: #c0c4c6;
$s-01-06: #b6bbbe;
$s-01-02: #adb2b5;
$s-01-07: #aaaeb0;
$s-02-05: #8a949a;
$s-02-02: #848b8f;
$s-02-01: #7c868c;
$s-01-00: #728692;
$s-02-03: #54636c;
$s-02-00: #34373a;

// Status
$sta-05-00: #905390;
$sta-03-01: #f9f2e7;
$sta-03-02: #5c4845;
$sta-02-00: #e6d03c;
$sta-02-01: #e0c35a;
$sta-03-00: #cb6c1f;
$sta-04-00: #ea4632;
$sta-04-01: #f45542;
$sta-01-05: #d3e3ca;
$sta-01-04: #b5d2a5;
$sta-01-03: #81be5f;
$sta-01-02: #8db577;
$sta-01-00: #7baf5e;
$sta-01-01: #56a479;

// Variables
$--n-24op: rgba(182, 182, 182, 0.2);
$pending-alert: #738692;

$color-light-gray: $--n-38;
$color-background: $s-02-00;
$color-background-selected: $--n-85;
$color-text: $s-02-02;
$color-text-selected: $p-01-01;
$color-dark-grey: $--n-87;
$color-kairos-blue: $p-01-00;
$color-kairos-blue-hover: $p-01-05;
$color-icon-unselected: $s-02-03;
$color-kairos-steel-blue: #4b8cb6;
$color-kairos-blue-highlight: #486e85;
$color-kairos-blue-disabled: #cdcdcd;
$color-kairos-blue-over: $--n-09;
$color-kairos-blue-disabled: $p-01-02;
$color-kairos-blue-text-disabled: #9b9b9b;
$color-kairos-blue-background: #d5ebfa;
$color-kairos-blue-form-input: #559ecb;
$color-kairos-blue-border: $p-01-04;
$color-kairos-blue-active: $p-01-03;
$color-kairos-dark-disabled: $--n-60;
$color-kairos-green-shifts: $sta-01-00;
$color-alabaster: #fafafa;
$color-gallery: $--n-05;
$color-gallery-dark: #ededed;
$color-white: $--n-00;
$color-white-two: $--n-03;
$color-white-three: $--n-12;
$color-greyish: $--n-24;
$color-greyish-brown: $--n-66;
$color-mid-grey: $s-01-01;
$color-nevada: #6a6d6f;
$color-silver: #c4c4c4;
$color-steel-grey: $--n-46;
$color-header-grey: $--n-65;
$color-pale-green: #a1cc89;
$color-tia-maria: #de390d;
$color-asparagus: $sta-01-00;

$color-toolbar-button-inactive: #646769;

$color-nav-dark: $color-dark-grey;

// Colors used on a dark background
$color-darkbg-main-text: $color-text-selected;
$color-darkbg-deselected-text: #9b9fa1;
$color-darkbg-field-label: $color-kairos-blue;
$color-darkbg-label: #a3b7bb;
$color-darkbg-disabled: #2c2f31;
$color-darkbg-text-disabled: #444b4f;
$color-good: $sta-01-01;
$color-warning: #d4c019;
$color-warning-two: $sta-03-01;
$color-warning-text: #e5991f;
$color-alert: $sta-04-00;
$color-good-opaque: rgba(86, 164, 121, 0.3);
$color-warning-opaque: rgba(212, 192, 25, 0.3);
$color-alert-opaque: rgba(234, 70, 50, 0.3);
$color-neutral: #7092be;
$color-lunch: #ffc816;

$color-darkbg-body: $--n-77;
$color-darkbg-hover: #2e3133;
$color-darkbg-unselected-tab: #34383a;
$color-darkbg-light-dropdown: #484d50;
$color-darkbg-search-box: #3c4245;
$color-darkbg-header-search-box: #373c3e;
$color-darkbg-selected-search-box: #4e5558;

$color-darkbg-scrollbar: $color-darkbg-hover;

$color-modal-overlay: rgba($color-nav-dark, 0.97);

// Colors used on a light background
$color-lightbg-body: $--n-03;
$color-lightbg-border: $--n-12;
$color-lightbg-hover: #efefef;

$color-lightbg-body-accent: #fbfbfb;
$color-lightbg-body-accent-border: #e5e5e5;

$color-lightbg-main-text: #676b6d;
$color-lightbg-dark-font: $--n-66;
$color-lightbg-darkest-font: $color-darkbg-selected-search-box;
$color-lightbg-light-font: #868686;
$color-unselected-tab-font: $color-steel-grey;
$color-lightbg-lighter-font: #9c9c9c;
$color-lightbg-greybg: $color-greyish;
$color-lightbg-greybg-text: #e7e7e7;
$color-lightbg-light-font-highlight: #b5b5b5;
$color-lightbg-cancel-button: #7f8182;

$color-lightbg-divider-line: #dedede;
$color-prime-tab: #dbdbdb;

$color-lightbg-scrollbar: $color-lightbg-light-font;
$color-lightbg-scrollbar-track: $color-lightbg-body;

$color-lightbg-shadow: rgba(225, 225, 225, 0.8);

$color-mediumbg-border: darken($color-lightbg-body, 7%);

$color-exception: $sta-03-00;

$list-selected-bar-color: #ebebeb;

// Colors used on New leaf
$color-green: #8bc63f;
$color-grey: #f1eff1;
$color-dark: $--n-82;
$color-table-row-even: #f9f9f9;
$color-active-button: #93c47d;
$color-active-border: #4c533c;
$color-error: #ff0000;
$color-error-button: #ea9899;
$color-error-border: #92301e;
$color-btn-disabled: #666666;
$color-bgd-btn-disabled: #cccccc;
$color-border-disabled: #999999;
$color-crm-label: #333333;
$color-link: #1155cc;
$color-blue-icon: #84c7ff;
$color-spinner-overlay: rgba(0, 0, 0, 0.222);
$color-btn-default: #007ad985;
$color-btn-default-border: #007ad9bd;
