@import './sizes';

$tab-top-border-size: 3px;

%tab {
  @extend %center-content;
  cursor: pointer;
  outline: none;
}

%nav-bar-active {
  @extend %transition-quick-ease-in-out;
  color: $color-text-selected;
  background-color: $color-background-selected;
  border-radius: 0 0 5px 5px;
}

%detail-tab-active {
  @extend %transition-quick-ease-in-out;
  background-color: white;

  border-right: 3px solid $color-alert;
}

%detail-tab-hover {
  @extend %detail-tab-active;
}

.nav-bar-tab {
  @extend %transition-quick-ease-in-out;
  @extend %tab;

  display: flex;
  flex-direction: column;

  width: 150px;

  background-color: $color-background;
  color: $color-text;

  font-size: $font-xsmall;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &:first-of-type {
    margin-left: 5px;
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  .icon {
    @extend %transition-quick-ease-in-out;
    @include size(14px);
    fill: $color-icon-unselected;
    margin-bottom: 10px;
  }

  &.active {
    @extend %nav-bar-active;

    .icon {
      @extend %transition-quick-ease-in-out;
      fill: $color-kairos-blue;
    }
  }

  &:hover:not(.active) {
    @extend %nav-bar-active;

    .icon {
      @extend %transition-quick-ease-in-out;
      fill: $color-kairos-blue;
    }
  }
}

.detail-tab {
  @extend %transition-quick-ease-in-out;
  @extend %tab;
  @include size(50px);
  @extend %flex-column;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border-right: 3px solid transparent;
  border-bottom: 1px solid $color-lightbg-border;

  .icon {
    @include size(16px);

    fill: $color-lightbg-dark-font;
  }

  .value {
    color: $color-alert;
    font-size: 7pt;
  }

  &.selected {
    @extend %detail-tab-active;
  }

  &:not(.selected) {
    background-color: $color-lightbg-body;
  }

  &:hover:not(.selected) {
    @extend %detail-tab-hover;

    color: $color-lightbg-light-font;
  }
}
