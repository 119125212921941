@import '../../common/colors';
@import '../../common/sizes';
@import '../../common/mixins';

$border-thin-light: 1px solid $--n-24op;

// Resource Management Tech List
$resource-management-header-height: 75px;
$resource-management-content-height: calc(
  100vh - #{$kairos-nav-bar-height} - #{$fulfillment-toolbar-height} - #{$resource-management-header-height} +
    26px
);
$resource-management-list-width: 252px;
p-table#resource-mgmt-tech-list {
  height: $resource-management-content-height;
  .ui-table {
    height: $resource-management-content-height;
    .ui-table-scrollable-wrapper {
      height: $resource-management-content-height;
      .ui-table-scrollable-view {
        height: $resource-management-content-height;
        .ui-table-scrollable-body {
          height: $resource-management-content-height;
          table {
            width: calc(100% - 15px);
            margin: 0 5px 0 10px;
            tbody {
              tr {
                &:first-child {
                  td.content {
                    .day-row {
                      height: 93px;
                      .shifts-main {
                        height: 83px;
                        margin-top: 12px;
                      }
                      .overlay {
                        top: 11px;
                        height: 82px;
                      }
                    }
                    .week-row {
                      height: 93px;
                      resource-mgmt-week-day {
                        height: 93px;
                        .day {
                          height: 93px;
                          .shifts-main {
                            margin-top: 12px;
                          }
                        }
                      }
                    }
                    .rows-filler {
                      .row {
                        height: 12px;
                        visibility: visible;
                      }
                    }
                    .month-row {
                      .day {
                        border-top: 1px solid $--n-24op;
                      }
                    }
                  }
                }
                td.list-item {
                  width: $resource-management-list-width;
                }
              }
              .no-techs {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: $resource-management-content-height;
                font-size: $font-medium;
                font-weight: 700;
                color: $color-lightbg-dark-font;
              }
            }
          }
        }
      }
    }
  }
}
#resource-mgmt-timeline-container {
  .timeline-container {
    .day-row {
      display: flex;
      flex-direction: row;
      position: relative;
      height: 83px;
      box-sizing: border-box;
      border-bottom: 1px solid $--n-24op;
      .hour-segment {
        flex: 1;
        box-sizing: border-box;
        border-right: 1px solid $--n-24op;
        background-color: transparent;
        z-index: 1;
        &:first-child {
          border-left: 1px solid $--n-24op;
        }
      }
      .shifts-main {
        position: absolute;
        width: 100%;
        height: 100%;
        border-top: 1px solid $--n-24op;
        background-color: $--n-05;
        .shifts-container {
          position: absolute;
          top: 5px;
          width: 100%;
          height: 70px;
          .shifts {
            position: relative;
            height: 100%;
            .shift-segment {
              position: absolute;
              height: 100%;
              width: 50px;
              height: 100%;
              &:hover {
                box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.2);
                //z-index: 1;
              }
              wfx-tooltip {
                z-index: 20;
              }
              .segment-color-container {
                position: absolute;
                height: 100%;
                width: 100%;
                opacity: 0.8;
                &.productive {
                  background-color: $sta-01-05;
                  border: 2px solid $sta-01-04;
                  border-radius: 2px;
                }
                &.non-productive {
                  @include resource-mgmt-hashed-background();
                  background-color: $--n-12;
                  border: 2px solid $--n-24;
                  border-radius: 2px;
                }
                &.off {
                  @include resource-mgmt-hashed-background();
                  background-color: $--n-12;
                  border: 2px solid $--n-24;
                  border-radius: 2px;
                }
              }
              .break {
                position: absolute;
                @include resource-mgmt-hashed-background();
                background-color: $--n-12;
                border: 2px solid $--n-24;
                border-radius: 2px;
                height: 100%;
                opacity: 0.8;
              }
              .inner-data-container {
                display: flex;
                flex-direction: row;
                position: relative;
                height: 100%;
                .segment-info {
                  position: absolute;
                  height: 100%;
                  box-sizing: border-box;
                  padding: 10px;
                  z-index: 1;
                  width: 100%;
                  overflow: hidden;
                  display: flex;
                  flex-direction: column;
                  &.hidden {
                    .shift-data {
                      visibility: hidden;
                    }
                  }
                  .shift-data {
                    visibility: visible;
                    width: 100%;
                    font-size: $font-xsmall-plus;
                    &.shift-name {
                      font-weight: 700;
                    }
                    color: $color-white;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 2px;
                    color: $sta-01-00;
                  }
                }
              }
              &:not(:first-of-type) {
                border-left: $border-thin-light;
              }
            }
          }
        }
      }
      .overlay {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 15px);
        height: calc(100% + 1px);
        box-sizing: border-box;
        pointer-events: none;
        margin-left: -10px;
        * {
          height: 100%;
        }
        .past {
          box-sizing: border-box;
          border-right: 1px solid $color-tia-maria;
          @include fulfillment-past-shadow-background();
          opacity: 0.4;
          z-index: 1;
        }
      }
    }
    .week-row {
      display: flex;
      flex-direction: row;
      position: relative;
      height: 83px;
      box-sizing: border-box;
      border-bottom: 2px solid $--n-24op;
      .overlay {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 15px);
        height: calc(100% + 2px);
        margin-left: -10px;
        box-sizing: border-box;
        pointer-events: none;
        z-index: 1;
        * {
          height: 100%;
        }
        .past {
          box-sizing: border-box;
          border-right: 1px solid $color-tia-maria;
          opacity: 0.4;
          padding-left: 10px;
          @include fulfillment-past-shadow-background();
        }
      }
    }
    .rows-filler {
      display: flex;
      flex-direction: row;
      flex: 1;
      position: relative;
      .row {
        position: relative;
        height: 0;
        visibility: hidden;
        border-left: 1px solid $--n-24op;
        width: 100%;
        &.is-today {
          border-left: 1px solid $color-tia-maria;
        }
        &:last-child {
          border-right: 1px solid $--n-24op;
        }
        &.first-day-of-week:nth-child(n + 1) {
          border-left: 1px solid $--n-24op;
          &.is-today {
            border-left: 2px solid $color-tia-maria;
          }
        }
        &:first-child() {
          .overlay {
            &.is-before-today {
              margin-left: -11px;
              width: calc(100% + 12px);
            }
          }
        }
        .overlay {
          position: absolute;
          width: calc(100% + 1px);
          height: calc(100% + 1px);
          z-index: 1;
          pointer-events: none;
          &.is-before-today {
            @include fulfillment-past-shadow-background();
            opacity: 0.4;
          }
        }
      }
    }
    .month-row {
      height: 83px;
      width: 100%;
      .month-row-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        background-color: $--n-05;
        height: 100%;
        .day {
          display: flex;
          flex-direction: column;
          flex: 1;
          position: relative;
          box-sizing: border-box;
          border-bottom: 2px solid $--n-24op;
          &:first-child() {
            .overlay {
              &.is-before-today {
                margin-left: -11px;
                width: calc(100% + 12px);
              }
            }
          }
          &:last-child() {
            border-right: 1px solid $--n-24op;
          }
          border-left: 1px solid $--n-24op;
          &.is-today {
            border-left: 1px solid $color-tia-maria;
          }
          &.first-day-of-week:nth-child(n + 1) {
            border-left: 1px solid $--n-24op;
            &.is-today {
              border-left: 2px solid $color-tia-maria;
            }
          }
          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            pointer-events: none;
            &.is-before-today {
              @include fulfillment-past-shadow-background();
              height: calc(100% + 2px);
              width: calc(100% + 1px);
              opacity: 0.4;
            }
          }
          .shifts-container {
            position: absolute;
            left: 10%;
            height: 100%;
            width: 80%;
            .shifts {
              position: relative;
              height: 100%;
              .shift {
                position: absolute;
                width: 100%;
                box-sizing: border-box;
                &:not(:last-of-type) {
                  border-bottom: $border-thin-light;
                }
                wfx-tooltip {
                  z-index: 20;
                }
                .shift-segment-color {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  opacity: 0.8;
                  z-index: 1;
                  &:hover {
                    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.2);
                  }
                  &.work {
                    background-color: $sta-01-05;
                    border: 2px solid $sta-01-04;
                    border-radius: 2px;
                  }
                  &.override {
                    @include resource-mgmt-hashed-background();
                    background-color: $--n-12;
                    border: 2px solid $--n-24;
                    border-radius: 2px;
                  }
                  // This must remain after the other shift types
                  // to use this style for days when they are off
                  &.off {
                    @include resource-mgmt-hashed-background();
                    background-color: $--n-12;
                    border: 2px solid $--n-24;
                    border-radius: 2px;
                  }
                }
                .break-container {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  .break {
                    position: absolute;
                    width: 100%;
                    @include resource-mgmt-hashed-background();
                    background-color: $--n-12;
                    border: 2px solid $--n-24;
                    border-radius: 2px;
                  }
                }
              }
            }
          }
        }
        .month-borders-container {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
