@import '../common/colors';
@import '../common/sizes';
@import '../common/mixins';

@import './components/accordion';
@import './components/list';

@import './components/resource-mgmt-list';

@import './components/tables';

@import '../common/popover';
@import '../components/calendar';

@include calendar_style();

p-calendar,
.ui-datepicker-calendar {
  * a {
    cursor: pointer;
  }
}

.p-dialog {
  background-color: $color-lightbg-body;
  width: 250px;
  height: auto;
  min-height: 150px;
  border-radius: 0px;
  .p-dialog-content {
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
    display: flex;
  }
}
.p-dialog {
  background-color: $color-white-two;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  .p-dialog-titlebar {
    background: $color-white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 36px;
    line-height: 24px;
    padding: 6px;
    margin: 6px 6px 0px 6px !important;
  }
  .p-dialog-content {
    overflow: auto;
    margin: 0px 6px 0px 6px !important;
    padding: 10px;
    font-size: .75rem;
    padding-bottom: 0px !important;
    border-right: 1px solid $color-white-three;
    border-left: 1px solid $color-white-three;
    z-index: 1;
    // &::after{
    // content: '';
    // position: absolute;
    // height: 100%;
    // width: 100%;
    // left: 0px;
    // bottom: 0px;
    // z-index: 1;
    // box-shadow: inset 0 -6px 6px -2px rgba(0, 0, 0, 0.1);
    // }
  }
  .p-dialog-footer {
    background-color: $color-white !important;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    text-align: right;
    // max-height: 36px;
    height: auto;
    padding: 0px;
    margin: 0px 6px 6px 6px !important;
  }
}

.shift-plan-editor {
  background-color: $color-lightbg-body;
  width: 250px;
  height: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid $color-white-three;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) !important;
  .p-dialog {
    height: 340px;
    .p-dialog-content {
      flex: 1;
    }
  }
}

/* global styles */
* label {
  font-size: $font-xsmall;
  color: $color-kairos-blue;
  margin-left: 6px;
}
input.ui-inputtext {
  background-color: $--n-05;
  &:host-context(.level-one) {
    background-color: $--n-00;
  }
  border-radius: 2px;
  border: 0px none !important;
  height: 22px;
}
.ui-datepicker {
  background-color: $color-white-two;
}
.ui-g-6 {
  p-calendar {
    width: calc(50% - 12px);
    input {
      width: calc(100% - 12px);
    }
  }
  p-inputmask {
    width: calc(50% - 12px);
    input {
      width: calc(100% - 12px);
    }
  }
}
p-listbox {
  .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
    color: $color-kairos-blue;
  }
  .ui-listbox .ui-listbox-list .ui-listbox-item {
    color: $color-lightbg-dark-font;
    background-color: $color-lightbg-body;
    border-bottom: 1px solid $color-lightbg-border;
  }
  .ui-listbox .ui-listbox-list .ui-listbox-item.p-highlight {
    background-color: $color-kairos-blue;
  }
  .ui-inputtext {
    font-size: $font-small;
  }
}

body .ui-inputswitch {
  width: 30px !important;
  height: 17.5px !important;
  .ui-inputswitch-slider {
    width: 30px !important;
    height: 17.5px !important;
  }
  .ui-inputswitch-slider:before {
    width: 12.5px !important;
    height: 12.5px !important;
    left: 2.5px;
    bottom: 2.5px;
  }
  &.ui-inputswitch-checked {
    .ui-inputswitch-slider:before {
      transform: translateX(12px);
    }
  }
}

body {
  p-calendar,
  p-inputMask {
    &[styleClass='half-width'] {
      overflow: hidden;
    }
  }
}

.float-calendar-left {
  margin-left: -146px !important;
}

.ui-inputtext::-webkit-input-placeholder {
  color: $color-white-three;
}

#tasks-performance-summary radial-thresholds {
  height: 162px;
  width: 162px;
  .outer-arc-good {
    fill: #a7abad4d;
    stroke: #a7abad4d;
  }
  .outer-arc-warning {
    fill: $color-warning-opaque;
    stroke: $color-warning-opaque;
  }
  .outer-arc-alert {
    fill: $color-alert-opaque;
    stroke: $color-alert-opaque;
  }
  .inner-arc.good {
    fill: $color-good;
    stroke: $color-good;
  }
  .inner-arc.warning {
    fill: $color-warning;
    stroke: $color-warning;
  }
  .inner-arc.alert {
    fill: $color-alert;
    stroke: $color-alert;
  }
  .text {
    fill: #2a2a2b;
    font-size: 0.8em;
    font-weight: 500;
  }
}
