@import '../../common/colors';
@import '../../common/sizes';
@import '../../common/mixins';

@mixin list-control-styles {
    * .list-item{
        background: $color-gallery;
        border-radius: 2px;
    }
}

$tile-width: calc(100% - 24px);

@mixin tile-styles {
    .tile {
        position: absolute;
        background: $color-gallery;
        padding-left: 6px;
        overflow: hidden;
        width: $tile-width;

        @extend %transition-super-quick-ease-in-out;

        &:hover {
            box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.15);
            z-index: 1;
        }
        &:active {
            box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.24);
            z-index: 2;
        }

        &.no-z-index {
            &:hover {
                z-index: unset;
            }
        }
    }
}

/* specify the cell sizes in CSS along with the primeng's table cell size property */
tech-list-container * .cammo-list{
    * tr{
        height: 83px;
        td{
            height: 83px;
        }
    }
    &.grouped-list{
        overflow-y: auto;
        .list-group {
            .list-group-header{
                height: 80px;
            }
        }
    }

}
simple-tech-list .infinite-scroll-container .cammo-list {
    * tr{
        height: 83px;
        td{
            height: 83px;
        }
    }
}
  
jobs-list * .cammo-list{
    * tr{
        height: 173px;

        td{
            height: 173px;
        }
    }
  }
  
task-list-container * .cammo-list{
    * tr {
        height: 83px;
        td {
            height: 83px;
        }
    }
}

resource-management-tech-list * .cammo-list{
    * tr{
        height: 83px;

        td{
            height: 83px;
        }
    }
}

.cammo-list{
    background: $color-white;
    @include list-control-styles;

    .ui-table{
        margin-top: 12px;
    }

    tr{
        td{
            .list-item{
                @include tile-styles;
                max-width: 288px;
                margin-left: 12px;
            }
        }
    }

    &.grouped-list{
        .list-group{
            .list-group-header {
                background: $--n-12;
                margin-bottom: 0;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                &:not(.expanded) {
                    height: 80px;
                    margin-bottom: 3px;
                    border-bottom-left-radius: 2px;
                    border-bottom-right-radius: 2px;
                }
                margin-left: 12px;
                width: 276px;
            }

            .list-items{
                position: relative;
                background: $--n-12;
                padding: 6px;
                margin-left: 12px;
                margin-bottom: 3px;
                .list-item {
                    @include tile-styles;
                    display: block;

                    margin-bottom: 3px;

                    .tile{
                        width: calc(100% - 12px);
                        * .tech-name {
                            max-width: 110px;
                        }
                    }

                    border-radius: 0px;
                    
                }
            }
        }
    }
}
