@import '../common/colors';
@import '../common/sizes';
@import '../common/placeholders';

$toolbar-button-height: 29px;
$section-button-size: 80px;
$local-nav-button-triangle-size: 7px;

%button {
  cursor: pointer;
  outline: none;
}

a.close {
  color: white;
  background-color: $color-alert;
}

.nav-bar-button {
  @extend %button;
  @extend %center-content;
  @include size(60px);

  position: relative;

  &:hover {
    .icon svg {
      @extend %transition-quick-ease-in-out;
      fill: $color-kairos-blue;
    }
  }

  .icon svg {
    @include size(14px);
    @extend %transition-quick-ease-in-out;
    fill: $color-icon-unselected;
  }
}

.nav-bar-button.alert {
  border-top: 3px solid $color-alert;
}

.toolbar-button {
  @extend %button;
  @extend %center-content;
  @include size($toolbar-button-height);

  background-color: $color-darkbg-body;
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  .icon svg {
    @extend %transition-quick-ease-in-out;
    @include size(12px);
    fill: $color-toolbar-button-inactive;
  }

  &.active {
    background-color: $color-nav-dark;

    svg {
      fill: $color-kairos-blue;
    }
  }

  &:hover:not(.active) {
    @extend %transition-quick-ease-in-out;
    background-color: $color-darkbg-hover;

    .icon svg {
      @extend %transition-quick-ease-in-out;
      fill: $color-kairos-blue;
    }
  }
}

.local-nav-section-button {
  @extend %flex-column;
  @extend %transition-quick-ease-in-out;
  @extend %button;
  @include size($section-button-size);

  flex: 0 0 $section-button-size;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  background-color: $color-nav-dark;

  color: $color-darkbg-deselected-text;
  font-size: 7pt;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;

  .text {
    width: 100%;
  }

  &:not(.selected) {
    @include create-box-shadow(-10px, 0, 20px, -5px, rgba(0, 0, 0, 0.4), 'inset');

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &.selected {
    position: relative;

    border-left: 3px solid $color-kairos-blue;

    background-color: $color-darkbg-body;

    color: $color-darkbg-main-text;
  }

  &:hover:not(.selected) {
    @extend %transition-quick-ease-in-out;
    background-color: $color-darkbg-hover;

    color: $color-darkbg-main-text;
  }

  &:first-child {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.4);
  }

  svg {
    @include size(20px);

    margin-bottom: 5px;

    fill: $color-kairos-blue;
  }
}

@mixin button_style {
  height: 18px;
  background-color: $p-01-00;
}
