@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import './sass/imports/common/index';

@import '~primeicons/primeicons.css';
@import '~primeng/resources/primeng.min.css';
@import './sass/imports/prime-ng/theme';
@import './sass/imports/prime-ng/wfx-custom';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

//==============================================================================
// Vendor Specific
//==============================================================================
::-webkit-scrollbar {
  width: 5px; /* for vertical scrollbars */
  height: 5px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-thumb {
  border-radius: 25px;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-darkbg-deselected-text;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-darkbg-deselected-text;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $color-darkbg-deselected-text;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $color-darkbg-deselected-text;
}

//==============================================================================
// General
//==============================================================================


*{
  padding: 0px;
  margin: 0px;
}


body {
  height: 100vh;

  margin: 0;
  // overflow: scroll;

  font-family: $font-family;
  color: $color-nav-dark;

  background-color: $color-background;
}

h1 {
  font-size: $font-xlarge;
  font-weight: 700;
  padding: 5px;
}

.allcaps {
  text-transform: uppercase;
}


svg {
  pointer-events: none;
}

#userAgentProblem {
  display: none;
}


.icon {
  height: $icon-height;
  width: $icon-width;
  padding: $padding-small;
  background: $color-white; 
}


button{
  margin-right: 20px;
  padding: 10px 20px;
  color: #ffffff;
  background: #8bc63f;
  border: none;
  border-radius: 5px !important;
  cursor: pointer;
}

.btn-status-red {
  background: $color-tia-maria;
}

.btn-disabled {
  border: $elem-size-1 solid $color-border-disabled;
  background-color: $color-bgd-btn-disabled;
  color: $color-btn-disabled;
  cursor: not-allowed;
}

.collapse{
  width: 100%;
  height: 50px;
}

.crm-link{
  color: blue;
  cursor: pointer;
}

.main-section-header {
  display: flex;
  align-items: center;
  .icon {
    height: $elem-size-25;
    width: $elem-size-25;
    background: $color-white;
    padding: $elem-size-5;
  }
  .title {
    padding: 0px $elem-size-10;
    background: $color-green;
    color: $color-white;
    flex-grow: 1;
    div {
      margin: $elem-size-5 0;
      font-size: $font-small;
    }
  }
}

.button-bar {
  display: flex;
  justify-content: flex-end;
  padding-top: $elem-size-30;
  padding-bottom: $elem-size-20;
  button {
    margin-right: $elem-size-20;
    padding: $elem-size-10 $elem-size-20;
    color: $color-white;
    background: $color-green;
    border: none;
    border-radius: $elem-size-5 !important;
    cursor: pointer;
  }
  button[disabled] {
    border: $elem-size-1 solid $color-border-disabled;
    background-color: $color-bgd-btn-disabled;
    color: $color-btn-disabled;
    cursor: not-allowed;
  }
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
  word-break: break-word;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
  word-break: break-word;
}


.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 0.3rem !important;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 900px !important;
  overflow-y: scroll;
  position: sticky;
  top: -1px;
}

// body .p-component,
// body .p-component .p-component {
//   font-family: "Work Sans", "Helvetica Neue", sans-serif;
//   text-decoration: none;
//   font-size: 0.75rem;
//   padding: 5px;
// }

.p-datatable table {
  table-layout: auto !important;
}

.p-datatable-thead {
  top: -1px;
  position: sticky;
}

.crm-container{
  width: 100%;
}

.pi{
font-family: 'primeicons';
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;
font-size: xx-small;
display: inline-block;
-webkit-font-smoothing: antialiased;
}

.sub-section {
  padding: 5px;
  margin-top: 10px;
}


.input-text {
  height: 25px;
}